var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var passiveSupported = false;

if (typeof window !== "undefined") {
  try {
    var options = Object.defineProperty({}, "passive", {
      get: function () {
        passiveSupported = true;
        return true;
      }
    });
    window.addEventListener("test", options, options);
    window.removeEventListener("test", options, options);
  } catch (err) {
    passiveSupported = false;
  }
}

exports.aggressive = passiveSupported ? {
  passive: false
} : false;
export default exports;