import _tslib from "tslib";
import _react from "react";
import _reactRemoveScrollBar from "react-remove-scroll-bar";
import _handleScroll from "./handleScroll";
import _aggresiveCapture from "./aggresiveCapture";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib_1 = _tslib;
var React = _react;
var react_remove_scroll_bar_1 = _reactRemoveScrollBar;
var handleScroll_1 = _handleScroll;
var aggresiveCapture_1 = _aggresiveCapture;

exports.getTouchY = function (event) {
  return event.changedTouches ? event.changedTouches[0].clientY : 0;
};

var classNames = {
  fullWidth: react_remove_scroll_bar_1.fullWidthClassName,
  zeroRight: react_remove_scroll_bar_1.zeroRightClassName
};

var extractRef = function (ref) {
  return ref && "current" in ref ? ref.current : ref;
};

var RemoveScroll = function (_super) {
  tslib_1.__extends(RemoveScroll, _super);

  function RemoveScroll() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.shouldPreventQueue = [];
    _this.touchStart = 0;
    _this.ref = React.createRef();

    _this.shouldPrevent = function (event) {
      var stack = RemoveScroll.stack.filter(function (el) {
        return el.props.enabled;
      });

      if (!stack.length || stack[stack.length - 1] !== _this) {
        return;
      }

      var delta = event.deltaY || exports.getTouchY(event);

      var sourceEvent = _this.shouldPreventQueue.filter(function (e) {
        return e.name === event.type && e.delta === delta && e.target === event.target;
      })[0];

      if (sourceEvent && sourceEvent.should) {
        event.preventDefault();
        return;
      }

      if (!sourceEvent) {
        var shardNodes = (_this.props.shards || []).map(extractRef).filter(Boolean).filter(function (node) {
          return node.contains(event.target);
        });
        var shouldStop = shardNodes.length > 0 ? _this.shouldCancelEvent(event, shardNodes[0]) : !_this.props.noIsolation;

        if (shouldStop) {
          event.preventDefault();
        }
      }
    };

    _this.shouldCancel = function (name, delta, target, should) {
      var event = {
        name: name,
        delta: delta,
        target: target,
        should: should
      };

      _this.shouldPreventQueue.push(event);

      setTimeout(function () {
        _this.shouldPreventQueue = _this.shouldPreventQueue.filter(function (e) {
          return e !== event;
        });
      }, 1);
    };

    _this.scrollTouchStart = function (event) {
      _this.touchStart = exports.getTouchY(event);
    };

    _this.scrollWheel = function (event) {
      _this.shouldCancel(event.type, event.deltaY, event.target, _this.shouldCancelEvent(event, _this.ref.current));
    };

    _this.scrollTouchMove = function (event) {
      _this.shouldCancel(event.type, exports.getTouchY(event), event.target, _this.shouldCancelEvent(event, _this.ref.current));
    };

    return _this;
  }

  RemoveScroll.prototype.componentDidMount = function () {
    RemoveScroll.stack.push(this);
    this.componentDidUpdate({
      enabled: false
    });
  };

  RemoveScroll.prototype.componentWillUnmount = function () {
    var _this = this;

    RemoveScroll.stack = RemoveScroll.stack.filter(function (inst) {
      return inst !== _this;
    });
    this.disable();
  };

  RemoveScroll.prototype.componentDidUpdate = function (oldProps) {
    if (oldProps.enabled !== this.props.enabled) {
      if (this.props.enabled) {
        this.enable();
      } else {
        this.disable();
      }
    }
  };

  RemoveScroll.prototype.enable = function () {
    if (typeof document !== "undefined") {
      document.addEventListener("wheel", this.shouldPrevent, aggresiveCapture_1.aggressive);
      document.addEventListener("touchmove", this.shouldPrevent, aggresiveCapture_1.aggressive);
      document.addEventListener("touchstart", this.scrollTouchStart, aggresiveCapture_1.aggressive);
    }
  };

  RemoveScroll.prototype.disable = function () {
    if (typeof window !== "undefined") {
      document.removeEventListener("wheel", this.shouldPrevent, aggresiveCapture_1.aggressive);
      document.removeEventListener("touchmove", this.shouldPrevent, aggresiveCapture_1.aggressive);
      document.removeEventListener("touchstart", this.scrollTouchStart, aggresiveCapture_1.aggressive);
    }
  };

  RemoveScroll.prototype.shouldCancelEvent = function (event, parent) {
    switch (event.type) {
      case "wheel":
      case "scroll":
        return handleScroll_1.handleScroll(parent, event, event.deltaY);

      case "touchmove":
        return handleScroll_1.handleScroll(parent, event, this.touchStart - exports.getTouchY(event));
    }

    return false;
  };

  ;

  RemoveScroll.prototype.render = function () {
    var _a = this.props,
        forwardProps = _a.forwardProps,
        children = _a.children,
        className = _a.className,
        removeScrollBar = _a.removeScrollBar,
        enabled = _a.enabled;
    var props = {
      ref: this.ref,
      onScrollCapture: this.scrollWheel,
      onWheelCapture: this.scrollWheel,
      onTouchMoveCapture: this.scrollTouchMove
    };
    return React.createElement(React.Fragment, null, removeScrollBar && enabled && React.createElement(react_remove_scroll_bar_1.RemoveScrollBar, {
      gapMode: "margin"
    }), forwardProps ? React.cloneElement(React.Children.only(children), props) : React.createElement("div", tslib_1.__assign({}, props, {
      className: className
    }), children));
  };

  RemoveScroll.classNames = classNames;
  RemoveScroll.defaultProps = {
    enabled: true,
    removeScrollBar: true
  };
  RemoveScroll.stack = [];
  return RemoveScroll;
}(React.Component);

exports.RemoveScroll = RemoveScroll;
export default exports;