var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var elementCouldBeScrolled = function (node) {
  var styles = window.getComputedStyle(node);
  return styles.overflowY !== "hidden" && !(styles.overflowY === styles.overflowX && styles.overflowY === "visible");
};

exports.handleScroll = function (endTarget, event, sourceDelta) {
  var delta = sourceDelta;
  var target = event.target;
  var targetInLock = endTarget.contains(target);
  var shouldCancelScroll = false;
  var isDeltaPositive = delta > 0;
  var availableScroll = 0;
  var availableScrollTop = 0;

  do {
    var scrollTop = target.scrollTop,
        scrollHeight = target.scrollHeight,
        clientHeight = target.clientHeight;
    var elementScroll = scrollHeight - clientHeight - scrollTop;

    if (scrollTop || elementScroll) {
      if (elementCouldBeScrolled(target)) {
        availableScroll += elementScroll;
        availableScrollTop += scrollTop;
      }
    }

    target = target.parentNode;
  } while (!targetInLock && target !== document.body || targetInLock && (endTarget.contains(target) || endTarget === target));

  if (isDeltaPositive && delta > availableScroll) {
    shouldCancelScroll = true;
  } else if (!isDeltaPositive && -delta > availableScrollTop) {
    shouldCancelScroll = true;
  }

  return shouldCancelScroll;
};

export default exports;